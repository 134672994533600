import Entity from "../../Extends/Entity";

export { FileEntity as default };

/**
 * Classe modelo FileEntity
 */
class FileEntity extends Entity {
  id;
  disk_name;
  file_name;
  file_size;
  content_type;
  title;
  description;
  field;
  attachment_id;
  attachment_type;
  is_public;
  path;
  sort_order;
  file_type_name;
  file_type_color;
  /**
   * Arquivo default que foi selecionado
   * no input
   * @type {File}
   */
  file_input;
}
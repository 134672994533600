export default [
  {
    path: '/',
    name: 'home',
    label: 'global.menu_nav.home',
    icon: 'ti ti-home-2',
    order: 10,
    permissions: ["*"],
    component: () => import('@/Modules/Dashboard/Home/HomeView')
  }
];
import CityEntity from "./CityEntity";
import Entity from "@/Services/Extends/Entity";
import FileCollection from "@/Services/FileSystem/FileCollection";
import FileEntity from "@/Services/FileSystem/Entities/FileEntity";
import StateEntity from "./StateEntity";

export { CompanyEntity as default };

/**
 * @class Entity referente a company
 * @author Roni Sommerfeld <roni@4tech.mobi>
 */
class CompanyEntity extends Entity {
  /**
   * @type {Number}
   */
  id;

  /**
   * @type {String}
   */
  name;

  /**
   * @type {String}
   */
  short_name;

  /**
   * @type {String}
   */
  token;

  /**
   * @type {String}
   */
  timezone;

  /**
   * @type {Number}
   */
  is_active;

  lat;

  lng;

  hasOne = {
    state: StateEntity,
    city: CityEntity,
  }

  attachOne = {
    logo: new FileCollection,
    logo_negative: new FileCollection
  }
}
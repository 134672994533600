import Entity from "@/Services/Extends/Entity";

export { NotifyEntity as default };

/**
* Notify Entity
* @author      Roni Sommerfeld <roni@4tech.mobi>
*/
class NotifyEntity extends Entity {
  /**
   * Id
   * @public
   * @type {string|null}
   */
  id = null;

  /**
   * type
   * @public
   * @type {string|null}
   */
  type = null;

  /**
   * message
   * @public
   * @type {string}
   */
  message;

  /**
   * Replacements
   * @public
   * @type {Object}
   */
  replacements = {};

  /**
   * Auto Dismiss in Notify
   * @public
   * @type {Boolean}
   */
  auto_dismiss = true;

  /**
   * Auto dismiss timer in secs
   * @public
   * @type {Number}
   */
  auto_dismiss_timer = 15;

  /**
   * Allows the message to be duplicated
   * @public
   * @type {Boolean}
   */
  allow_duplicates = false;

  /**
   * Type of message eg: text, link_text ...
   * @public
   * @type {String}
   */
  message_type = 'text';

  /**
   * URL to redirect
   * @public
   * @type {String|Null}
   */
  link = null;

  /**
   * Type Link external eg: external ou internal
   * @public
   * @type {String|Null}
   */
  link_type = null;

  /**
   * Class to insert in button link
   * @public
   * @type {String|Null}
   */
  link_class = null;

  /**
   * Text used in Link
   * @public
   * @type {String|Null}
   */
  link_text = null;

  /**
   * Icon to use in Link
   * @public
   * @type {String|Null}
   */
  link_icon = null;
}
import HTTPService from '@/Services/HTTP/HTTPService';

export { UserHTTP as default };

/**
 * Responsible class that manages the connections with the api
 */
class UserHTTP {
  /**
   * endpoint api
   * @type {String}
   * @default api/v1/backend/auth
   */
  endpoint = '/api/v1/backend/users';

  /**
   * Recover Data User From API
   * @returns {Object.<HTTPResponse>}
   */
  async getUserData() {
    let response = await HTTPService.get(`${this.endpoint}`);

    return response;
  }

  /**
   * Logout User from Cookie
   * @returns {Object.<HTTPResponse>}
   */
  async logout() {
    let response = await HTTPService.post(`${this.endpoint}/logout`);

    return response;
  }

  /**
   * Change schema DB to Cookie
   * @returns {Object.<HTTPResponse>}
   */
  async requestChangeSchema(params) {
    let response = await HTTPService.post(`${this.endpoint}/change-schema`, params, 'change-schema');

    return response;
  }
}
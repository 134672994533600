export { LocalStorageServiceSingleton as default };
/**
 * Class responsible for managing the LocalStorage data of the
 * project
 */
class LocalStorageService {
  /**
   * @type {window.localStorage}
   */
  storage;

  constructor() {
    try {
      window.localStorage.setItem("StorageCheck", 1);
      window.localStorage.removeItem("StorageCheck");
      this.storage = window.localStorage;
    } catch (error) {
      console.error("-> LocalStorage", error);
    }
  }

  /**
   * Set a value to storage
   * @param {String} key key name
   * @param {Mixed} value to save
   */
  setItem(key, value) {
    this.storage[key] = value;
  }

  /**
   * Retrieves an item saved in a localStorage location
   * @param {String} key 
   * @param {Mixed} default_value 
   * @returns {Mixed}
   */
  getItem(key, default_value = null) {
    return this.storage[key] || default_value;
  }

  /**
   * Remove a item from localStorage
   * @param {String} key
   */
  removeItem(key) {
    delete this.storage[key];
  }

  /**
   * Set an object to save to localStorage
   * @param {String} key 
   * @param {Object} value 
   */
  setObject(key, value) {
    this.storage[key] = JSON.stringify(value);
  }

  /**
   * Returns a value from a saved object
   * @param {String} key 
   * @param {Object|Null} default_value 
   * @returns {Object|Null}
   */
  getObject(key, default_value = null) {
    return this.storage[key] ? JSON.parse(this.storage[key]) : default_value;
  }
}

const LocalStorageServiceSingleton = new LocalStorageService();
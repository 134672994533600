import Collection from "../Collections/Collection";
import DateFormat from "../Helpers/DateFormat";

export { Entity as default };

/**
 * Generic class used for Entities
 * @author Roni Sommerfeld <roni@4tech.mobi>
 */
class Entity {

  /**
   * Method that dynamically adds values ​​to the
   * Entity that extends, allowing to map the fields that the
   * EntityClass waits.
   *
   * @public
   *
   * @param {Object} data_object {param, param...}
   *
   * @returns {void}
   */
  async set(data_object) {
    if (!data_object) {
      return;
    }

    for (let key in data_object) {
      if (this.isKeyNotRelationAndExists(key)) {
        this.setValueProperty(key, data_object[key]);
      }
    }

    this.setHasOne(data_object);
    this.setHasMany(data_object);
    this.setAttachMany(data_object);
    this.setAttachOne(data_object);
  }

  /**
   * Verifica se a chave existe na entity,
   * verifica se a chave nao existe no HasOne
   * verifica se a chave nao existe em HasMany
   *
   * @private
   *
   * @param {String} key
   *
   * @returns {Boolean}
   */
  isKeyNotRelationAndExists(key) {
    // Verifique se a chave existe no objeto "this"
    const key_exists_in_this = Object.prototype.hasOwnProperty.call(this, key);

    // Verifique se a chave não existe em "hasOne"
    const key_not_is_hasone = !this.hasOne || !(key in this.hasOne);

    // Verifique se a chave não existe em "hasMany"
    const key_not_is_hasmany = !this.hasMany || !(key in this.hasMany);

    // Verifique se a chave não existe em "attachOne"
    const key_not_is_attachone = !this.attachOne || !(key in this.attachOne);

    // Verifique se a chave não existe em "attachMany"
    const key_not_is_attachmany = !this.attachMany || !(key in this.attachMany);

    return (key_exists_in_this && key_not_is_hasone && key_not_is_hasmany
      && key_not_is_attachone && key_not_is_attachmany);
  }

  /**
   * Seters value in property of class entity
   *
   * @public
   *
   * @param {String} key
   * @param {Mixins} value
   *
   * @returns {void}
   */
  setValueProperty(key, value) {
    if (value instanceof File) {
      this.setValueAttachOne(key, value);
      return;
    }

    if (value instanceof FileList) {
      this.setValueAttachMany(key, value);
      return;
    }

    if (!Object.prototype.hasOwnProperty.call(this, key)) {
      throw new Error(`${key} not exists in Entity`);
    }

    if (key.endsWith("_at")) {
      this[key] = DateFormat(value);
      return;
    }

    this[key] = value;
  }

  /**
   * Checks if Entity has hasOne properties
   * where it has relationships with other Entities
   *
   * @private
   *
   * @param {Object} data_object {param, param ....}
   *
   * @returns {void}
   */
  setHasOne(data_object) {
    if (this.hasOne) {
      for (let relation in this.hasOne) {
        if (Object.prototype.hasOwnProperty.call(data_object, relation)) {
          this[relation] = null;
          if (data_object[relation]) {
            const entity = new this.hasOne[relation]();

            if (!(entity instanceof Entity)) {
              throw new Error(`Class for relation ${relation} does not extend Entity`);
            }

            this[relation] = entity;
            this[relation].set(data_object[relation]);
          }
        }
      }
    }
  }

  setAttachMany(data_object) {
    if (this.attachMany) {
      for (let file_relation in this.attachMany) {
        this[file_relation] = this.attachMany[file_relation];

        if (Object.prototype.hasOwnProperty.call(data_object, file_relation)) {
          if (data_object[file_relation]) {
            this.attachMany[file_relation].addAttachFromAPI(data_object[file_relation]);
            this[file_relation] = this.attachMany[file_relation];
          }
        }
      }
    }
  }

  setAttachOne(data_object) {
    if (!this.attachOne) {
      return;
    }

    if (this.attachOne) {
      for (let file_relation in this.attachOne) {
        this[file_relation] = this.attachOne[file_relation];

        if (Object.prototype.hasOwnProperty.call(data_object, file_relation)) {
          if (data_object[file_relation]) {
            this.attachOne[file_relation].addAttachFromAPI(data_object[file_relation]);
            this[file_relation] = this.attachOne[file_relation];
          }
        }
      }
    }
  }

  /**
   * Checks if Entity has hasMany properties
   * where it has relationships with other Entities
   *
   * @private
   *
   * @param {Object} data_object {param, param ....}
   *
   * @returns {void}
   */
  setHasMany(data_object) {
    if (this.hasMany) {
      for (let relation in this.hasMany) {

        const relation_struct = this.getDescontructRelation(relation, this.hasMany);
        this[relation] = new Collection(relation_struct.options.collection_id || 'id');

        if (Object.prototype.hasOwnProperty.call(data_object, relation)) {

          data_object[relation].forEach(item => {
            let entity = new relation_struct['class_instance']();
            if (!(entity instanceof Entity)) {
              throw new Error(`Class for relation ${relation} does not extend Entity`);
            }
            entity.set(item);

            this[relation].add(entity);
          });
        }
      }
    }
  }

  setValueAttachOne(key, value) {
    if (!this.attachOne || !(key in this.attachOne)) {
      throw new Error(`Entity don't have property ${key} in attachOne`);
    }
    console.log(this.attachOne[key], this);
    this.attachOne[key].addAttachFromInput(value);
  }

  setValueAttachMany(key, value) {
    if (!this.attachMany || !(key in this.attachMany)) {
      throw new Error(`Entity don't have property ${key} in attachMany`);
    }

    this.attachMany[key].addAttachFromInput(value);
  }

  /**
   * Deconstructs the relationship extracting the class and personalized options
   * that can have
   *
   * @param {String} relation
   * @param {Array|Class} relation_type
   * @returns {Object}
   * @property {Object.<Class>} class_instance Class used in relationship
   * @property {Object} options Object with properties
   * @property {String} options.collection_id Index used in Collections
   */
  getDescontructRelation(relation, relation_type) {
    if (typeof relation_type[relation] !== 'function') {
      if (!relation_type[relation][0] || typeof relation_type[relation][0] !== 'function') {
        throw new Error(`Class for relation ${relation} does not exists`)
      }

      return {
        class_instance: relation_type[relation][0],
        options: relation_type[relation][1] || {}
      }
    }

    return {
      class_instance: relation,
      options: {}
    };
  }
}
export default [{
  path: '/support',
  label: 'Suporte',
  icon: 'ti ti-message-chatbot',
  permissions: ['support.registers.*', 'support.tickets.*'],
  order: 200,
  component: () => import('@/Modules/Support/SupportView.vue'),
  children: [
    {
      path: 'tickets',
      name: 'support-tickets',
      label: 'Tickets',
      permissions: ['support.tickets.*'],
      icon: 'ti ti-ticket',
      component: () => import('@/Modules/Support/Tickets/TicketsView.vue'),
      children: [
        {
          path: 'tickets-user',
          redirect: 'support-tickets-user-list',
          label: 'Meus Tickets',
          icon: 'ti ti-list',
          permissions: ['support.tickets.tickets_user'],
          component: () => import('@/Modules/Support/Tickets/TicketUsers/TicketUsersView.vue'),
          ignore_children: true,
          children: [
            {
              path: '',
              name: 'support-tickets-user-list',
              component: () => import('@/Modules/Support/Tickets/TicketUsers/Views/ListView.vue'),
            },
            {
              path: 'create',
              name: 'support-tickets-user-create',
              component: () => import('@/Modules/Support/Tickets/TicketUsers/Views/FormView.vue'),
            },
            {
              path: 'update/:id',
              name: 'support-tickets-user-update',
              component: () => import('@/Modules/Support/Tickets/TicketUsers/Views/FormView.vue'),
            }
          ]
        },
      ]
    }
  ]
}];
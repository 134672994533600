export default [{
    path: '/auth',
    component: () => import('@/Modules/Auth/AuthView.vue'),
    redirect: 'auth-login',
    ignore_route: true,
    children: [
        {
            path: 'login',
            name: 'auth-login',
            component: () => import('@/Modules/Auth/Login/Views/LoginView.vue')
        },
        {
            path: 'recover-password',
            name: 'auth-recover-password',
            component: () => import('@/Modules/Auth/RecoverPassword/Views/RecoverPasswordView.vue')
        },
        {
            path: 'reset-password/:user_id/:user_code',
            name: 'auth-reset-password',
            component: () => import('@/Modules/Auth/ResetPassword/Views/ResetPasswordView.vue')
        },
    ]
}]
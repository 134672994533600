export { Collection as default };

/**
* Items List Collection
*
* @author  Roni Sommerfeld <roni@4tech.mobi>
*/
class Collection {
  /**
   * Confirmed identities list
   *
   * @public
   * @type {Object.<ItemListEntity}
   */
  index = {};

  /**
   * Confirmed identities list
   *
   * @public
   * @type {Array.<ItemListEntity}
   */
  items = [];

  /**
   * Number of items in the list
   *
   * @public
   * @type {Number}
   */
  count = 0;

  /**
   * Defines Index key id
   *
   * @private
   * @type {Number}
   */
  index_id;

  /**
   * Holds last added item to collection
   *
   * @public
   * @type {Object}
   */
  last_added;

  /**
   * Holds last added item deleted from collection
   *
   * @public
   * @type {Object}
   */
  last_deleted;

  constructor(index_id) {
    this.index_id = index_id;
  }

  /**
   * Returns an Item by id
   *
   * @public
   * @param {Number} id id
   *
   * @return {ItemListEntity|null}
   */
  get(id) {
    return this.index[id] || null;
  }

  /**
   * Adds an item to the collection
   *
   * @public
   * @param {ItemListEntity} item item to add
   *
   * @return {Boolean}
   */
  add(item) {
    if (this.index[item[this.index_id]]) {
      return false;
    }

    this.index[item[this.index_id]] = item;
    this.items.push(item);
    this.count++;
    this.last_added = item;
    return true;
  }

  /**
   * Adds an unique item to the list, removing everything else from the collection
   *
   * @public
   * @param {ItemListEntity} item item to add
   *
   * @return {Boolean}
   */
  unique(item) {
    if (this.index[item[this.index_id]]) {
      return true;
    }

    this.reset();
    this.add(item);
    return true;
  }

  /**
   * Adds or removes an item from the collection list
   *
   * In order to add an item, the object must me provided
   *
   * @public
   * @param {String|Number}    id
   * @param {Object|undefined} item
   *
   * @return {void}
   */
  toggle(id, item) {
    (this.index[id]) ? this.delete(id) : this.add(item);
  }

  /**
   * Updates an item in the list
   *
   * @public
   * @param {ItemListEntity} item item to add
   *
   * @return {void}
   */
  update(item) {
    if (!this.index[item[this.index_id]]) {
      return false;
    }

    this.delete(item[this.index_id]);
    this.add(item);
    return true;
  }

  /**
   * Adds multiple items to the collection
   *
   * @public
   * @param {Object<ItemListEntity>} items  item to add
   *
   * @return {void}
   */
  addItems(items) {
    for (let item in items) {
      this.add(items[item]);
    }
  }

  /**
   * Delets an item from the collection
   *
   * @public
   * @param {String|Number} id item id in the list
   *
   * @return {Boolean}
   */
  delete(id) {
    if (!this.index[id]) {
      return false;
    }

    let item = this.index[id];
    let index = this.items.indexOf(item);
    this.items.splice(index, 1);
    this.last_deleted = item;
    delete this.index[id];
    this.count--;
    return true;
  }

  /**
   * Pops the last item from the collxtion
   *
   * @public
   * @param {String|Number} id item id in the list
   *
   * @return {void}
   */
  pop() {
    let item = this.items.pop();
    delete this.index[item[this.index_id]];
    this.count--;
    return item;
  }

  /**
    * Returns the first item in the list or null if list is empty
    *
    * @public
    * @return {Object|null}
    */
  getFirst() {
    return this.items[0] || null;
  }

  /**
    * Returns the last added item
    *
    * @public
    * @return {Object|null}
    */
  getLastAdded() {
    return this.last_added;
  }

  /**
    * Returns the last deleted item
    *
    * @public
    * @return {Object|null}
    */
  getLastDeleted() {
    return this.last_deleted;
  }

  /**
  * Returns collection index keys as an array
  *
  * @public
  * @return {Array}
  */
  getIndexKeys() {
    return Object.keys(this.index);
  }

  /**
   * Checks if item exists in the collection
   *
   * @public
   * @param {String} index index id
   *
   * @return {Boolean}
   */
  exists(index) {
    return this.index[index] ? true : false;
  }

  /**
   * Resets the collection list to it's initial state
   *
   * @public
   * @return {void}
   */
  reset() {
    this.index = {};
    this.items = [];
    this.count = 0;
  }
}
import Entity from "@/Services/Extends/Entity";

export {StateEntity as default};

/**
 * @class Entity referente a States
 * @author Roni Sommerfeld <roni@4tech.mobi>
 */
class StateEntity extends Entity{
    /**
     * @type {Number}
     */
    id;

    /**
     * @type {String}
     */
    name;

    /**
     * @type {String}
     */
    uf;   
}
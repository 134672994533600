import { reactive } from "vue";
import UserEntity from './Entities/UserEntity';
import LocalStorageService from '@/Services/Storage/LocalStorageService';
import UserHTTP from "./HTTP/UserHTTP";
import EventState from "@/Services/Events/EventState";
import EventMessage from "@/Services/Events/EventMessage";
import CompanyEntity from "./Entities/CompanyEntity";
import UserHTTPMapping from "./HTTP/UserHTTPMapping";
import NotifySingleton from "@/Services/Notifications/NotifySingleton";

export { UserInstanceSingleton as default };

/**
 * @class responsável em gerir os dados do usuário
 * na plataforma conectada
 * @author Roni Sommerfeld <roni@4tech.mobi>
 */
class UserSingleton {
  /**
   * Identifies whether user data has been loaded
   * @type {Boolean}
   * @default false
   */
  user_loaded = false;

  /**
   * Classe Entity User Logged
   * @type {Object.<UserEntity>}
   */
  user_logged = new UserEntity();

  /**
   * Company has selected in backend view
   * @type {Object.<CompanyEntity>|Null}
   */
  company_selected = new CompanyEntity;

  /**
   * HTTP API Requests
   * @type {Object.<UserHTTP>}
   */
  http_service = new UserHTTP();

  /**
   * HTTP Mapping Entity
   * @type {Object.<UserHTTPMapping>}
   */
  http_mapping = new UserHTTPMapping();

  /**
   * EventState UserInstance
   * @type {Object.<EventState>}
   */
  event_state = new EventState();

  /**
   * EventMessage UserInstance
   * @type {Object.<EventMessage>}
   */
  event_message = new EventMessage();

  /**
   * Checks whether the user is logged in or not
   * and making the connection with the token
   *
   * @returns {void}
   */
  async loadUserSession() {
    if (!this.user_loaded) {
      await this.getUserData();
    }
  }

  /**
   * Set the user value and map it to the entity
   * @param {Object.<HTTPResponse>} response 
   * @returns {void}
   */
  setUserData(response) {
    this.http_mapping.mapUserData(this.user_logged, response);
    this.http_mapping.mapCompanySelect(this.company_selected, response);
    this.user_loaded = true;

    if (!this.company_selected) {
      const company = this.user_logged.company || this.user_logged.companies.getFirst();
      this.mapCompanySelect(this.company_selected, company);
    }
  }

  /**
   * Setter company
   *
   * @param {Object.<CompanyEntity} company
   */
  setCompanySelected(company) {
    this.company_selected = company;
  }

  /**
   * Recover UserData from acessToken
   * @returns {Object.<UserEntity>}
   */
  async getUserData() {
    this.event_state.setLoading('login');
    const response = await this.http_service.getUserData();
    this.event_state.setSuccess('login', response.success);

    if (!response.success) {
      this.event_message.broadcast("user-not-logged");
      this.reset();
      return;
    }

    this.setUserData(response);
    return this.user_logged;
  }

  /**
   * Identifies if the logged in user has permission
   * to perform task
   * @param {String} permission
   * @returns {Boolean}
   */
  hasPermission(permission) {
    console.log("permission", permission, this.user_logged.permissions);
    return true;
  }

  /**
   * Change shema in backend
   * @returns {void}
   */
  async requestChangeSchema(params) {
    this.event_state.setLoading('set-schema');
    const response = await this.http_service.requestChangeSchema(params);
    this.event_state.setSuccess('set-schema', response.success);

    if (!response.success) {
      NotifySingleton.addError(response.messages);
      return;
    }

    NotifySingleton.addSuccess(response.messages);
    this.http_mapping.mapCompanySelect(this.company_selected, response);
    this.event_message.broadcast("set-schema");
  }

  /**
   * Logoff the user from the system
   * @returns {void}
   */
  async logout() {
    this.event_state.setLoading('logout');
    const response = await this.http_service.logout();
    this.event_state.setSuccess('logout', response.success);

    if (!response.success) {
      NotifySingleton.addError(response.messages);
      return;
    }

    this.event_message.broadcast("user-not-logged");
    this.reset();
  }

  /**
   * Identifica se o usuario possui uma permissao do menu
   *
   * @param {String} permission_value
   * @returns {boolean}
   */
  async findPermissionUser(permission_value) {
    let response = false;

    Object.keys(this.user_logged.permissions).some(permission => {
      if (permission.startsWith(permission_value)) {
        response = true;
      }
    });

    return response;
  }

  /**
   * Save variable storage in localStorage
   *
   * @returns {void}
   */
  saveStorage() {
    LocalStorageService.setObject('l_app_company', this.company_selected);
  }

  /**
   * Loads cached data from layout settings
   * @returns {void}
   */
  loadStorage() {
    let company_selected = LocalStorageService.getObject('l_app_company');

    if (!company_selected) {
      return;
    }

    this.company_selected = company_selected;
  }

  /**
   * reset all values userInstance
   */
  reset() {
    this.user_loaded = false;
    this.user_logged = new UserEntity();
    this.company_selected = new CompanyEntity();
  }
}

const UserInstanceSingleton = reactive(new UserSingleton());
export default {
  /**
   * Diretiva para clicar fora de uma area e fechar sozinho
   *
   * @param {HTMLElement} el
   * @param {Object} binding Object
   * @property {Object} value Object
   * @property {Function} value.data Function
   * @property {String|Object} value.params Parameters object or string
   */
  mounted(el, binding) {
    // Função de tratamento do evento de clique fora do elemento
    const handleClickOutside = (event) => {
      if (!el.contains(event.target) && el !== event.target && binding.value) {
        const { context, params } = binding.value || {};
        if (context && typeof context === 'function') {
          context(params);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    // Remove o ouvinte de evento quando o componente é desmontado
    el._clickOutside = handleClickOutside;
  },
  beforeUnmount(el) {
    document.removeEventListener('click', el._clickOutside);
  }
};
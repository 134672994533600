export { SimpleCollection as default };

/**
* Simple Collection List without id defined
* @author Roni Sommerfeld <roni@4tech.mobi>
*/
class SimpleCollection {
  /**
   * Itens by Indexes
   * @public
   * @type {Object.<ItemListEntity}
   */
  index = {};

  /**
   * All Items
   * @public
   * @type {Array.<ItemListEntity}
   */
  items = [];

  /**
   * Number of items in the list
   * @public
   * @type {Number}
   */
  count = 0;

  /**
   * Returns an Item by id
   * @public
   * @param {Number} id id
   *
   * @return {ItemListEntity|null}
   */
  get(id) {
    return this.index[id] || null;
  }

  /**
   * Checks if item exists in the collection
   *
   * @public
   * @param {String} index index id
   *
   * @return {Boolean}
   */
  exists(index) {
    return this.index[index] ? true : false;
  }

  /**
   * Adds an item to the collection
   * @public
   * @param {Array|Object}  item item to add
   * @param {String|Number} id   item id
   *
   * @return {Boolean}
   */
  add(item, id) {
    if (this.index[id]) {
      return false;
    }

    this.index[id] = item;
    this.items.push(item);
    this.count++;
    return true;
  }

  /**
   * Adds multiple items to the collection
   * @public
   * @param {Array} items items to add
   *
   * @return {void}
   */
  addItems(items) {
    for (let item in items) {
      this.add(items[item], item);
    }
  }

  /**
   * Adds multiple items to the collection
   *
   * @public
   *
   * @since 7.0.0
   *
   * @param {Array} items items to add
   *
   * @return {void}
   */
  addItemsAsKeys(items) {
    items.forEach((item) => {
      this.add(item, item);
    })
  }

  /**
   * Delets an item from the collection
   * @public
   * @return {Boolean}
   */
  delete(id) {
    if (!this.index[id]) {
      return false;
    }

    let item = this.index[id];
    let index = this.items.indexOf(item);
    this.items.splice(index, 1);
    delete this.index[id];
    this.count--;
    return true;
  }

  /**
  * Returns collection index keys as an array
  * @public
  * @return {Array}
  */
  getIndexKeys() {
    return Object.keys(this.index);
  }

  /**
   * Sort itens using param in list
   *
   * @param {String} direction
   *
   * @returns {Array}
   */
  resortByDirection(direction) {
    return this.items.sort((a, b) => {
      return parseInt(a[direction]) - parseInt(b[direction]);
    });
  }

  /**
   * Chunk Items of Array
   *
   * @param {Number} size
   * @returns
   */
  chunk(size) {
    const chunks = [];
    let index = 0;

    while (index < this.items.length) {
      chunks.push(this.items.slice(index, index + size));
      index += size;
    }

    return chunks;
  }

  /**
   * Resets the collection list to it's initial state
   * @public
   * @return {void}
   */
  reset() {
    this.index = {};
    this.items = [];
    this.count = 0;
  }
}
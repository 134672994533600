import LanguageService from '@/Services/Language/LanguageService';
import LocalStorageService from '@/Services/Storage/LocalStorageService';
import { reactive } from 'vue';

export { LayoutInstanceSingleton as default };

/**
 * Class responsible for managing the App layout settings
 */
class LayoutSingleton {
  /**
   * App Layout Settings
   *
   * @type {Object}
   */
  storage = {
    layout_orientation: 'horizontal',
    theme: '',
    sidebar_type: 'full',
    sidebar_position: 'fixed',
    header_position: 'fixed',
    language: '',
    logo_light: '',
    logo_dark: '',
    app_name: '',
  }

  /**
   * Identify if viewport is mobile or desktop
   *
   * @type {String}
   * @default desktop (mobile or desktop)
   */
  device_viewport = 'desktop';

  /**
   * horizontal or vertical menu type
   *
   * @param {String} value horizontal or vertical
   */
  set layout_orientation(value) {
    this.storage.layout_orientation = value;
    this.saveStorage();
  }

  /**
   * Retrieve orientation layout is horizontal or vertical
   *
   * @returns {String}
   */
  get layout_orientation() {
    return this.storage.layout_orientation;
  }

  /**
   * if the sidebar is full or mini-sidebar
   *
   * @param {String} value full or mini-sidebar
   */
  set sidebar_type(value) {
    this.storage.sidebar_type = value;
    this.saveStorage();
  }

  /**
   * Retrieve sidebar is full or mini-sidebar
   *
   * @returns {String}
   */
  get sidebar_type() {
    return this.storage.sidebar_type;
  }

  /**
   * if the sidebar is fixed
   *
   * @param {String} value fixed
   */
  set sidebar_position(value) {
    this.storage.sidebar_position = value;
    this.saveStorage();
  }

  /**
   * Retrieve sidebar position
   *
   * @returns {String}
   */
  get sidebar_position() {
    return this.storage.sidebar_position;
  }

  /**
   * fixed keeps header stuck on scroll
   *
   * @param {String} value fixed or fluid
   */
  set header_position(value) {
    this.storage.header_position = value;
    this.saveStorage();
  }

  /**
   * Retrieve fixed keeps header stuck on scroll
   *
   * @returns {String}
   */
  get header_position() {
    return this.storage.header_position;
  }

  /**
   * This is a setter function that sets the value of a language property in storage and saves the
   * storage.
   * @param {String} value - The value parameter is the new value that is being set for the language property
   * in the storage object.
   */
  set language(value) {
    let language = value.toLocaleLowerCase();

    if (!LanguageService.available_languages.includes(language)) {
      language = LanguageService.language_default;
    }

    this.storage.language = language;
    LanguageService.setLanguage(language);

    this.saveStorage();
  }

  /**
   * This is a getter function that returns the language stored in the object's storage property.
   * @returns {String} `language` property of the `storage` object.
   */
  get language() {
    return this.storage.language;
  }

  /**
   * Theme using in layout
   * @param {String} value light or dark
   * @returns {void}
   */
  set theme(value) {
    this.storage.theme = value;
    this.saveStorage();
  }

  /**
   * Get the theme used in layout
   * @returns {String} `theme` property of the `storage` object.
   */
  get theme() {
    return this.storage.theme;
  }

  /**
   * Logo Light used in App
   * @param {String} value url path logo
   * @returns {void}
   */
  set logo_light(value) {
    this.storage.logo_light = value;
    this.saveStorage();
  }

  /**
   * Get the logo_light used in layout
   * @returns {String} `logo_light` property of the `storage` object.
   */
  get logo_light() {
    return this.storage.logo_light;
  }

  /**
   * Logo Dark used in App
   * @param {String} value url path logo
   * @returns {void}
   */
  set logo_dark(value) {
    this.storage.logo_dark = value;
    this.saveStorage();
  }

  /**
   * Get the logo_dark used in layout
   * @returns {String} `logo_dark` property of the `storage` object.
   */
  get logo_dark() {
    return this.storage.logo_dark;
  }

  /**
   * App name
   * @param  {String} value App name
   * @returns {void}
   */
  set app_name(value) {
    this.storage.app_name = value;
    this.saveStorage();
  }

  /**
   * Get the app_name used in layout
   * @returns {String} `app_name` property of the `storage` object.
   */
  get app_name() {
    return this.storage.app_name;
  }

  /**
   * Save variable storage in localStorage
   *
   * @returns {void}
   */
  saveStorage() {
    LocalStorageService.setObject('l_app', this.storage);
  }

  /**
   * Loads cached data from layout settings
   * @returns {void}
   */
  loadStorage() {
    let data = LocalStorageService.getObject('l_app');

    if (!data) {
      return;
    }

    this.storage = Object.assign(this.storage, data);
  }
}

const LayoutInstanceSingleton = reactive(new LayoutSingleton);

export default [{
  path: '/company',
  label: 'company.menu_label',
  icon: 'ti ti-building',
  order: 100,
  permissions: ["company.registers.*"],
  component: () => import('@/Modules/Company/CompanyView.vue'),
  children: [
    {
      path: 'registers',
      name: 'company-registers',
      label: 'company.menu_registers',
      permissions: ["company.registers.*"],
      icon: 'ti ti-folders',
      component: () => import('@/Modules/Company/Registers/RegistersView.vue'),
      children: [
        {
          path: 'units',
          redirect: 'company-registers-units-list',
          label: 'company.registers.units.menu_label',
          permissions: ["company.registers.units"],
          icon: 'ti ti-home-star',
          component: () => import('@/Modules/Company/Registers/Units/UnitsView.vue'),
          ignore_children: true,
          children: [
            {
              path: '',
              name: 'company-registers-units-list',
              component: () => import('@/Modules/Company/Registers/Units/Views/ListView.vue'),
            },
            {
              path: 'create',
              name: 'company-registers-units-create',
              component: () => import('@/Modules/Company/Registers/Units/Views/FormView.vue'),
            },
            {
              path: 'update/:id',
              name: 'company-registers-units-update',
              component: () => import('@/Modules/Company/Registers/Units/Views/FormView.vue'),
            }
          ]
        },
        {
          path: 'stores',
          redirect: 'company-registers-stores-list',
          label: 'Empresas',
          permissions: ["company.registers.stores"],
          icon: 'ti ti-building',
          component: () => import('@/Modules/Company/Registers/Stores/StoresView.vue'),
          ignore_children: true,
          children: [
            {
              path: '',
              name: 'company-registers-stores-list',
              component: () => import('@/Modules/Company/Registers/Stores/Views/ListView.vue'),
            },
            {
              path: 'create',
              name: 'company-registers-stores-create',
              component: () => import('@/Modules/Company/Registers/Stores/Views/FormView.vue'),
            },
            {
              path: 'update/:id',
              name: 'company-registers-stores-update',
              component: () => import('@/Modules/Company/Registers/Stores/Views/FormView.vue'),
            }
          ]
        },
        {
          path: 'departments',
          redirect: 'company-registers-departments-list',
          label: 'Departamentos',
          permissions: ["company.registers.departments"],
          icon: 'ti ti-home-star',
          component: () => import('@/Modules/Company/Registers/Departments/DepartmentView.vue'),
          ignore_children: true,
          children: [
            {
              path: '',
              name: 'company-registers-departments-list',
              component: () => import('@/Modules/Company/Registers/Departments/Views/ListView.vue'),
            },
            {
              path: 'create',
              name: 'company-registers-departments-create',
              component: () => import('@/Modules/Company/Registers/Departments/Views/FormView.vue'),
            },
            {
              path: 'update/:id',
              name: 'company-registers-departments-update',
              component: () => import('@/Modules/Company/Registers/Departments/Views/FormView.vue'),
            }
          ]
        },
      ],
    },
  ]
}];
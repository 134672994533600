import CompanyEntity from "../Entities/CompanyEntity";

export { UserHTTPMapping as default };

/**
 * @description class responsible for mapping data from
 * Entity of User
 * @author Roni Sommerfeld <roni@4tech.mobi>
 */
class UserHTTPMapping {
  /**
   * Map user data
   *
   * @param {Object.<UserEntity>} entity
   * @param {Object.<HTTPResponse>} response
   */
  mapUserData(entity, response) {
    entity.set(response.results.user);
    entity.full_name = `${entity.first_name} ${entity.last_name}`;
  }

  mapCompanySelect(entity, response) {
    entity.set(response.results.company);
    entity.short_name = this.handleShortName(entity.name);
  }

  handleShortName(company_name) {
    if (!company_name) {
      return company_name;
    }

    let explode = company_name.split(" ");
    if (explode.length == 1) {
      company_name = company_name.slice(0, 2).toUpperCase()
    } else {
      company_name = explode.map((word) => {

        if (word.length > 2) {
          return word.slice(0, 1).toUpperCase();
        }

        return word;
      })

      company_name = company_name.join("");
    }

    return company_name;
  }
}
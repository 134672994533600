export {
    EventState as
    default
};

/**
 * @class Gerencia os eventos e estados de funcoes
 * ao decorrer da aplicacao
 * @author Roni Sommerfeld <roni@4tech.mobi>
 */
class EventState {
    /**
     * Defines loading state
     * @type {Object}
     */
    events = {};

    /**
     * Stores all callback functions
     * @type {Object}
     */
    callbacks = {};

    /**
     * Gets the state of an event
     * @param {String|Array} id
     * @returns {String|null}
     */
    get(id) {
        return this.events[this.getId(id)] || null;
    }

    /**
     * Sets a custom event event state
     * @param {String|Array} id
     * @param {String} state
     * @returns {void}
     */
    set(id, state) {
        id = this.getId(id);
        this.events[id] = state;
        this._callBack(id, state);
    }

    /**
     * Deletes an event
     * @param {String} id
     */
    unset(id) {
        id = this.getId(id);
        delete this.events[id];
        delete this.callbacks[id];
    }

    /**
     * Sets State to Loading
     * @param {String} id
     */
    setLoading(id) {
        this.set(this.getId(id), 'loading');
    }

    /**
     * Sets State to success if load value is true, or error if false
     * @param {String} id
     */
    setSuccess(id, value) {
        id = this.getId(id);
        this.set(id, value ? 'success' : 'error');
    }

    /**
     * Registers an on Loaded function to be called on state change
     * @param {String} id
     * @param {String} state
     * @param {Function} callable
     */
    on(id, state, callable) {
        id = this.getId(id);
        this._setCallback(id, state, callable);
        this._callBack(id, state);
    }

     /**
     * Destroys a registered callback
     * @param {String} id
     * @param {String} state
     * @param {Function} callable
     */
    destroy(id, state, callable) {
        id = this.getId(id);
        if (!this.callbacks[id] || !this.callbacks[id][state]) return;
        this.callbacks[id][state] = this.callbacks[id][state].filter(cb => cb !== callable);
    }

    /**
     * Sets a loadded callback function
     * @param {String} id
     * @param {String} state
     * @param {Function} callable
     */
    _setCallback(id, state, callback) {
        if (!this.callbacks[id]) this.callbacks[id] = {};
        if (!this.callbacks[id][state]) this.callbacks[id][state] = [];
        this.callbacks[id][state].push(callback);
    }

    /**
     * Calls all registered loaded calbacks
     * @param {*} id
     * @param {*} state
     * @returns {void}
     */
    _callBack(id, state) {
        if (this.events[id] !== state || !this.callbacks[id] || !this.callbacks[id][state]) return;
        this.callbacks[id][state].forEach(callback => callback());
        this.callbacks[id][state] = [];
    }

    /**
     * Transforms id to string when is passed as an Array
     * joining all values with a separator
     * @param {String} id 
     * @returns {String}
     */
    getId(id) {
        return Array.isArray(id) ? id.join(';') : id;
    }
}

import { reactive } from 'vue';
import EventMessage from "@/Services/Events/EventMessage";

export { NavSingleton as default };

/**
 * @description Class responsible for managing menu states
 * navigation identifying which menu is
 * active or not
 * 
 * @author Roni Sommerfeld <roni@4tech.mobi>
 */
class NavigationStateSingleton {
  /**
   * Event Messages
   * @type {Object.<EventMessage>}
   */
  event_message = new EventMessage();

  /**
   * Stores the states of the menus that are in the app
   *
   * @type {Object}
   * @property {Boolean} user_menu User profile list menu
   * @property {Boolean} nav_menu Mobile or Menu with active navigation links
   * @property {Boolean} language_menu Active language list
   * @property {Boolean} notification_menu Active Menu Notification
   * @property {Boolean} header_mobile_menu Active Menu Mobile Header
   */
  dropdown_active = {
    user_menu: false,
    nav_menu: false,
    notification_menu: false,
    language_menu: false,
    header_mobile_menu: false,
  };

  /**
   * Enables or disables a dropdown menu option
   *
   * @param {String} identifer
   * @returns {void}
   */
  toggle(identifier, broadcaster_close = true) {
    let value = this.dropdown_active[identifier];

    this.dropdown_active[identifier] = !value;

    if (broadcaster_close) {
      this.event_message.broadcast('closeAllMenusExcept', identifier);
    }
  }

  /**
   * Open a menu property
   *
   * @param {String} identifer
   * @returns {void}
   */
  openMenu(identifier) {
    this.dropdown_active[identifier] = true;
    this.event_message.broadcast('closeAllMenusExcept', identifier);
  }

  /**
   * Close a menu property
   *
   * @param {String} identifer
   * @returns {void}
   */
  closeMenu(identifier) {
    this.dropdown_active[identifier] = false;
  }
}

const NavSingleton = reactive(new NavigationStateSingleton());
import { reactive } from "vue";
import BaseNotify from "./BaseNotify";

export { NotifyInstanceSingleton as default };

/**
* Handles All Notifications
*
* @author Roni Sommerfeld <roni@4tech.mobi>
*/
class NotifySingleton extends BaseNotify { }

const NotifyInstanceSingleton = reactive(new NotifySingleton);
export default [{
  path: '/inspections',
  label: 'Inspeções',
  icon: 'ti ti-check-box',
  permissions: ['inspections.activities.*', 'inspections.registers.*'],
  order: 200,
  component: () => import('@/Modules/Inspections/InspectionView.vue'),
  children: [
    {
      path: 'activities',
      name: 'inspections-activities',
      label: 'Atividades',
      permissions: ['inspections.activities.*'],
      icon: 'ti ti-check-box',
      component: () => import('@/Modules/Inspections/Activities/InspectionActivityView.vue'),
      children: [
        {
          path: 'vehicle-weights',
          redirect: 'inspections-activities-vehicleweights-list',
          label: 'Pesagens de Veículos',
          icon: 'ti ti-car',
          permissions: ['inspections.activities.vehicle_weights'],
          component: () => import('@/Modules/Inspections/Activities/VehicleWeights/VehicleWeightView.vue'),
          ignore_children: true,
          children: [
            {
              path: '',
              name: 'inspections-activities-vehicleweights-list',
              component: () => import('@/Modules/Inspections/Activities/VehicleWeights/Views/ListView.vue'),
            },
            {
              path: 'create',
              name: 'inspections-activities-vehicleweights-create',
              component: () => import('@/Modules/Inspections/Activities/VehicleWeights/Views/FormView.vue'),
            },
            {
              path: 'update/:id',
              name: 'inspections-activities-vehicleweights-update',
              component: () => import('@/Modules/Inspections/Activities/VehicleWeights/Views/FormView.vue'),
            }
          ]
        },
        {
          path: 'user-tracking-hours',
          redirect: 'inspections-activities-user-tracking-hours-list',
          label: 'Registro de Horas',
          icon: 'ti ti-clock',
          permissions: ['inspections.activities.user_tracking_hours'],
          component: () => import('@/Modules/Inspections/Activities/UserTrackingHour/UserTrackingHourView.vue'),
          ignore_children: true,
          children: [
            {
              path: '',
              name: 'inspections-activities-user-tracking-hours-list',
              component: () => import('@/Modules/Inspections/Activities/UserTrackingHour/Views/ListView.vue'),
            },
            {
              path: 'create',
              name: 'inspections-activities-user-tracking-hours-create',
              component: () => import('@/Modules/Inspections/Activities/UserTrackingHour/Views/FormView.vue'),
            },
            {
              path: 'update/:id',
              name: 'inspections-activities-user-tracking-hours-update',
              component: () => import('@/Modules/Inspections/Activities/UserTrackingHour/Views/FormView.vue'),
            }
          ]
        },
      ]
    },
    {
      path: 'registers',
      name: 'inspections-registers',
      label: 'Registros',
      permissions: ['inspections.registers.*'],
      icon: 'ti ti-check-box',
      component: () => import('@/Modules/Inspections/Registers/InspectionRegisterView.vue'),
      children: [
        {
          path: 'users',
          redirect: 'inspections-registers-users-list',
          label: 'Usuários',
          icon: 'ti ti-user',
          permissions: ['inspections.registers.users'],
          component: () => import('@/Modules/Inspections/Registers/Users/UserView.vue'),
          ignore_children: true,
          children: [
            {
              path: '',
              name: 'inspections-registers-users-list',
              component: () => import('@/Modules/Inspections/Registers/Users/Views/ListView.vue'),
            },
            {
              path: 'create',
              name: 'inspections-registers-users-create',
              component: () => import('@/Modules/Inspections/Registers/Users/Views/FormView.vue'),
            },
            {
              path: 'update/:id',
              name: 'inspections-registers-users-update',
              component: () => import('@/Modules/Inspections/Registers/Users/Views/FormView.vue'),
            }
          ]
        },
      ]
    },
  ]
}];
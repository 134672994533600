import CompanyEntity from './CompanyEntity'
import Entity from "@/Services/Extends/Entity";
export { UserEntity as default };

/**
 * @class responsavel em gerir os dados do usuario
 * @author Roni Sommerfeld <roni@4tech.mobi>
 */
class UserEntity extends Entity {
    /**
     * @type {String}
     */
    first_name;

    /**
     * @type {String}
     */
    last_name;

    /**
     * @type {String}
     */
    full_name;

    /**
     * @type {String}
     */
    email;

    /**
     * @type {Object.<UserRole>}
     */
    role;

    /**
     * @type {String}
     */
    avatar;

    /**
     * @type {Number}
     */
    is_super;

    /**
     * @type {Object}
     */
    permissions;

    /**
     * relation hasOne
     * @type {Object.<Class>}
     */
    hasOne = {
        company: CompanyEntity,
    }

    /**
     * relation hasMany
     * @type {Array.<Class>}
     */
    hasMany = {
        companies: [CompanyEntity, { collection_id: "token" }],
    }
}
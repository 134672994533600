export default function DateFormat(value, format = 'YYYY-MM-DD HH:mm:ss'){
  if(!value){
    return value;
  }

  const d = new Date(value);

  return format.replace(/YYYY/g, d.getFullYear())
    .replace(/MM/g, String(d.getMonth() + 1).padStart(2, '0'))
    .replace(/DD/g, String(d.getDate()).padStart(2, '0'))
    .replace(/HH/g, String(d.getHours()).padStart(2, '0'))
    .replace(/mm/g, String(d.getMinutes()).padStart(2, '0'))
    .replace(/ss/g, String(d.getSeconds()).padStart(2, '0'));
}
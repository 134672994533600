import { createApp } from 'vue'
import RouterService from '@/Services/Router/RouterService';
import LanguageService from '@/Services/Language/LanguageService';
import AppView from './App.vue';
import UserSingleton from '@/Classes/User/UserSingleton';
import LayoutSingleton from '@/Services/App/LayoutSingleton';
import ClickOutsideDirective from './Directives/ClickOutsideDirective';
// import './registerServiceWorker';

class App {

  /**
   * Vue's App Object
   * 
   * @type {Object}
   */
  app;

  /**
   * VUE ROUTER
   *
   * @type {Object.<Router>}
   */
  router;

  /**
   * @description Initialize Vue settings
   * 
   * @returns {void}
   */
  async initialize() {
    this.setApp();
    this.layoutConfigure();
    await this.loadSessionUser();
    this.loadRouter();
    await this.loadLanguages();
    this.loadDirectives();
    this.app.mount('#app')
  }

  /**
   * @description Add createApp to the App variable
   * 
   * @returns {void}
   */
  setApp() {
    this.app = createApp(AppView);
  }

  /**
   * @description Load the dependencies and uses that the app may have
   * 
   * @returns {void}
   */
  loadRouter() {
    this.router = RouterService.getRouter();
    this.app.use(this.router);
  }

  /**
   * @description Load Languages 
   * 
   * @returns {void}
   */
  async loadLanguages() {
    const i18n = await LanguageService.getI18n();
    this.app.use(i18n);
  }

  /**
   * @description Load directives used in VUE
   *
   * @returns {void}
   */
  loadDirectives() {
    this.app.directive('click-outside', ClickOutsideDirective);
  }

  /**
   * Carrega os dados salvos em cache dos tokens de
   * user e company
   * @returns {void}
   */
  async loadSessionUser() {
    if (!document.location.pathname.startsWith("/auth")) {
      await UserSingleton.loadUserSession();
    }
  }

  /**
   * @description The function loads storage for the layout controller.
   * 
   * @returns {void}
   */
  layoutConfigure() {
    LayoutSingleton.loadStorage();

    if (!LayoutSingleton.language) {
      LayoutSingleton.language = navigator.language;
    }

    if (!LayoutSingleton.theme) {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        LayoutSingleton.theme = 'dark'
      } else {
        LayoutSingleton.theme = 'light'
      }
    }
  }
}

const app = new App();
app.initialize();

import { createWebHistory, createRouter } from 'vue-router';
import UserSingleton from '@/Classes/User/UserSingleton';
export { RouterServiceSingleton as default };

/**
 * @description This is a class that creates a router for a web application by
 * loading routes from modules and subfolders.
 *
 * @class RouterService
 *
 * @author Roni Sommerfeld <roni@4tech.mobi>
 */
class RouterService {

  /**
   * @description  is initializing an empty array called `routes`.
   * This array will be used to store the routes for the web application.
   *
   * @type {Array}
   */
  routes = [];

  /**
   * @description is initializing an empty object called `modulesContext`. This object will
   * be used to store the context of the modules that will be loaded later
   *
   * @type {Object}
   */
  modulesContext = {};

  /**
   * @description is initializing an empty object that will be used to store the
   * cached results of loading routes from subfolders within modules. This is done to avoid making
   * repeated calls to `require.context` when loading routes from the same subfolder within a module.
   *
   * @type {Object}
   */
  subfolderModulesCache = {};

  /**
   * @description This function loads and creates a router for the
   * modules in a JavaScript application.
   */
  constructor() {
    this.modules = require.context('@/Modules', true, /Routes\.js$/);
    this.modulesContext = require.context('@/Modules', true, /\.\/[^/]+\/Routes\.js$/);

    this.loadModuleRoutes();
    this.loadRoutes();
    this.createRouter();
    this.loadRulesGuard();
  }

  /**
   * @description This function creates a router object with specified routes and scroll behavior for a web
   * application using JavaScript.
   *
   * @returns {void}
   */
  createRouter() {
    this.router = createRouter({
      history: createWebHistory(),
      routes: this.routes,
      scrollBehavior() {
        return {
          x: 0,
          y: 0
        };
      }
    });
  }

  /**
   * @description The function loads default routes.
   *
   * @returns {void}
   */
  loadRoutes() {
    //need the last routes
    this.routes = [...this.routes, ...[
      {
        path: '/404',
        name: '404',
        ignore_route: true,
        component: () => import('@/Modules/404View.vue')
      },
      {
        path: '/:catchAll(.*)',
        ignore_route: true,
        redirect: '/404'
      }]
    ];
  }

  /**
   * Rules for secure routes forcing the user to be logged in.
   * @returns {void}
   */
  loadRulesGuard() {
    this.router.beforeEach((to, from, next) => {
      if (to.name.startsWith('auth')) {
        next();
      } else if (!UserSingleton.user_loaded && !to.name.startsWith('auth')) {
        next({ name: 'auth-login' });
      } else {
        next();
      }
    });
  }

  /**
   * @description This function loads module routes and caches subfolder modules to avoid repeated calls to
   * require.context.
   *
   * @returns {void}
   */
  loadModuleRoutes() {
    const modulePaths = this.modules.keys().filter((modulePath) => modulePath.includes('/Routes.js'));

    for (const modulePath of modulePaths) {
      const moduleRoutes = this.modules(modulePath).default;
      this.routes.push(...moduleRoutes);
    }
  }

  /**
   * @description Verifica se o usuário tem permissão para acessar a rota
   * e redireciona para a rota apropriada.
   * @param {Object} to - O objeto de rota para o redirecionamento.
   * @param {Object} from - O objeto de rota de onde a navegação veio.
   * @param {Function} next - Função para chamar para prosseguir com a navegação.
   * @returns {void}
   */
  checkAccessAndRedirect(to, from, next) {
    const routePath = to.path;
    const matchingRoute = this.routes.find(route => route.path === routePath);

    if (matchingRoute) {
      // Verifique se o usuário tem permissão para acessar a rota principal
      if (!UserSingleton.hasPermission(matchingRoute)) {
        next({ name: '404' });
        return;
      }

      const children = matchingRoute.children;

      if (children.length > 0) {
        // Verifique se o usuário tem permissão para acessar qualquer um dos filhos
        const allowedChildren = children.filter(child => UserSingleton.hasPermission(child));

        if (allowedChildren.length > 0) {
          // Redireciona para o primeiro filho com permissão
          const firstAllowedChild = allowedChildren[0];
          next({ name: firstAllowedChild.name });
          return;
        }
      }
    }

    // Nenhum rota encontrada ou nenhum filho com permissão, redireciona para algum lugar adequado
    next({ name: '404' });
  }

  /**
   * @description The function returns the router object.
   *
   * @returns {Object} return this.router createRouter().
   */
  getRouter() {
    return this.router;
  }
}

const RouterServiceSingleton = new RouterService();

import SimpleCollection from "../Collections/SimpleCollection";
import NotifyEntity from "./Entities/NotifyEntity";

/**
* Handles All Notifications
*
* @author Roni Sommerfeld <roni@4tech.mobi>
*/
export default class BaseNotify {
  /**
   * items zones
   * @public
   * @type {Object.<SimpleCollection>}
   */
  zones = new SimpleCollection;

  /**
   * Default zone if options.zones not exists
  *
  * @private
   *
   * @type {Object}
   */
  static ZONES_DEFAULT = ['float'];

  /**
   * Adds a success notification
   *
   * @param {string} message      notification message
   * @param {Object}  replacements replacement to the message
   * @param {Object} options {zones, allow_duplicates, message_type, link, link_text, link_icon, auto_dismiss, link_class}
   *
   * @return {void}
   */
  addSuccess(message, replacements, options = {}) {
    this.addNotification('success', message, replacements, options);
  }

  /**
   * Adds an error notification
   *
   * @param {string} message      notification message
   * @param {Object}  replacements replacement to the message
   * @param {Object} options      {zones, allow_duplicates, message_type, link, link_text, link_icon, auto_dismiss, link_class}
   *
   * @return {void}
   */
  addError(message, replacements, options = {}) {
    this.addNotification('danger', message, replacements, options);
  }

  /**
   * Adds a warning notification
   *
   * @param {string} message     notification message
   * @param {Object}  replacements replacement to the message
   * @param {Object} options {zones, allow_duplicates, message_type, link, link_text, link_icon, auto_dismiss, link_class}
   *
   * @return {void}
   */
  addWarning(message, replacements, options = {}) {
    this.addNotification('warning', message, replacements, options);
  }

  /**
   * Adds a info notification
   *
   * @param {string} message     notification message
   * @param {Object}  replacements replacement to the message
   * @param {Object} options {zones, allow_duplicates, message_type, link, link_text, link_icon, auto_dismiss, link_class}
   *
   * @return {void}
   */
  addInfo(message, replacements, options = {}) {
    this.addNotification('info', message, replacements, options);
  }

  /**
   * Adds a notification to the poll
   *
   * @private
   *
   * @param {string} type         notification type
   * @param {string} message      notification message
   * @param {Object}  replacements replacement to the message
   * @param {Object} options      {zones, allow_duplicates, message_type, link, link_text, link_icon, auto_dismiss, link_class}
   *
   * @return {void}
   */
  addNotification(type, message, replacements, options = {}) {
    const zones = Array.isArray(options.zones)
      ? options.zones
      : (typeof options.zones == 'string' ? [options.zones] : BaseNotify.ZONES_DEFAULT);

    delete options.zones;

    zones.forEach(zone => {
      const notification = this.mapNotificationItem(type, message, replacements, zone, options);

      if (!notification.allow_duplicates) {
        this.removeMessageDuplicate(zone, notification);
      }

      if (!this.zones.exists(zone)) {
        this.zones.add(new SimpleCollection(), zone);
      }

      this.zones.index[zone].add(notification, notification.id);

      if (notification.auto_dismiss) {
        this.startTimer(notification.id, zone);
      }
    });
  }

  /**
  * if a message already exists in a given zone
  * remove the ID of the message if it already exists
  *
  * @param {String}   zone The zone to check for duplicate messages
  * @param {NotifyEntity} notification The notification to check for duplication
  *
  * @returns {void}
  */
  removeMessageDuplicate(zone, notification) {
    if (!this.zones.exists(zone)) {
      return;
    }

    const messages_in_zone = this.zones.get(zone);
    const duplicate = messages_in_zone.items.find(item => item.message === notification.message);

    if (duplicate) {
      this.removeNotification(duplicate.id, zone)
    }
  }

  getMessageZone(zone, id) {
    if (!this.zones.exists(zone) || !this.zones.index[zone].exists(id)) {
      return;
    }

    return this.zones.index[zone].get(id);
  }

  /**
   * Map items in NotifyEntity
   *
   * @private
   *
   * @param {string} type         notification type
   * @param {string} message      notification message
   * @param {Object}  replacements replacement to the message
   * @param {String} zone         The zone to check for duplicate messages
   * @param {Object} options      {zones, allow_duplicates, message_type, link, link_text, link_icon, auto_dismiss, link_class}
   *
   * @return {NotifyEntity}
   */
  mapNotificationItem(type, message, replacements, zone, options) {
    let notification = new NotifyEntity;
    notification.type = type;
    notification.message = message;
    notification.id = Math.random().toString(16).slice(2);
    notification.replacements = replacements;
    notification.set(options);

    return notification;
  }

  /**
   * Starts the timer to auto remove the message notification
   *
   * @private
   *
   * @param {String} id   notification id
   * @param {String} zone The zone to check for duplicate messages
   *
   * @return {void}
   */
  startTimer(id, zone) {
    const self = this;
    const notify = this.getMessageZone(zone, id);

    setTimeout(() => {
      self.removeNotification(id, zone);
    }, notify.auto_dismiss_timer * 1000)
  }

  /**
   * Remove a notification from the poll
   *
   * @param {String} id notification id
   * @public
   * */
  removeNotification(id, zone) {
    if (!this.zones.exists(zone) || !this.zones.index[zone].exists(id)) {
      return;
    }

    delete this.zones.index[zone].delete(id);
  }
}
<template>
  <div v-if="NotifySingleton.zones.index[zone] && NotifySingleton.zones.index[zone].count"
    :class="`container-${zone} ${content_class} ${notify_locale}`">
    <div v-for="notification in NotifySingleton.zones.index[zone].items" :key="notification.id"
      class="alert alert-dismissible text-white border-0" :class="`alert-${notification.type} bg-${notification.type}`">

      <button type="button" class="btn-close" @click="onDismiss(notification)" aria-label="Close"></button>

      <div class="d-flex align-items-center font-medium me-3 me-md-0">
        <i :class="[icons[notification.type], `text-${notification.type}`]" aria-hidden="true"></i>

        <component :is="partials[notification.message_type] || partials.default" :notification="notification" />
      </div>
    </div>
  </div>
</template>

<script setup>
import NotifySingleton from '@/Services/Notifications/NotifySingleton';
import { defineProps } from 'vue';
import TextSimple from './Partials/TextSimple.vue';
import TextLink from './Partials/TextLink.vue';

const props = defineProps({
  zone: {
    type: String,
    default: 'float'
  },
  notify_locale: {
    type: String,
    default: 'top-left'
  },
  content_class: {
    type: String,
    default: '',
  }
});

const icons = {
  'error': 'fas fa-exclamation-circle',
  'warning': 'fas fa-exclamation-circle',
  'success': 'fas fa-check-circle',
  'info': 'ti ti-info-circle fs-5 me-2',
}

const partials = {
  'text': TextSimple,
  'text_link': TextLink,
  'default': TextSimple,
}

const onDismiss = (notification) => {
  NotifySingleton.removeNotification(notification.id, props.zone);
}
</script>

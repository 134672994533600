export default [{
  path: '/pgrs',
  label: 'PGRS',
  icon: 'ti ti-trash',
  permissions: ['pgrs.registers.*', 'pgrs.activities.*'],
  order: 200,
  component: () => import('@/Modules/PGRS/PGRSView.vue'),
  children: [
    {
      path: 'registers',
      name: 'pgrs-registers',
      label: 'Registros',
      permissions: ['pgrs.registers.*'],
      icon: 'ti ti-folders',
      component: () => import('@/Modules/PGRS/Registers/RegistersView.vue'),
      children: [
        {
          path: 'bags',
          redirect: 'pgrs-registers-bags-list',
          label: 'Sacos de Lixos',
          icon: 'ti ti-trash',
          permissions: ['pgrs.registers.bags'],
          component: () => import('@/Modules/PGRS/Registers/Bags/BagsView.vue'),
          ignore_children: true,
          children: [
            {
              path: '',
              name: 'pgrs-registers-bags-list',
              component: () => import('@/Modules/PGRS/Registers/Bags/Views/ListView.vue'),
            },
            {
              path: 'create',
              name: 'pgrs-registers-bags-create',
              component: () => import('@/Modules/PGRS/Registers/Bags/Views/FormView.vue'),
            },
            {
              path: 'update/:id',
              name: 'pgrs-registers-bags-update',
              component: () => import('@/Modules/PGRS/Registers/Bags/Views/FormView.vue'),
            }
          ]
        },
        {
          path: 'users',
          redirect: 'pgrs-registers-users-list',
          label: 'Usuários',
          icon: 'ti ti-users',
          permissions: ['pgrs.registers.users'],
          component: () => import('@/Modules/PGRS/Registers/Users/UsersView.vue'),
          ignore_children: true,
          children: [
            {
              path: '',
              name: 'pgrs-registers-users-list',
              component: () => import('@/Modules/PGRS/Registers/Users/Views/ListView.vue'),
            },
            {
              path: 'create',
              name: 'pgrs-registers-users-create',
              component: () => import('@/Modules/PGRS/Registers/Users/Views/FormView.vue'),
            },
            {
              path: 'update/:id',
              name: 'pgrs-registers-users-update',
              component: () => import('@/Modules/PGRS/Registers/Users/Views/FormView.vue'),
            }
          ]
        },

        {
          path: 'stores',
          redirect: 'pgrs-registers-stores-list',
          label: 'Empresas',
          icon: 'ti ti-building',
          permissions: ['pgrs.registers.stores'],
          component: () => import('@/Modules/PGRS/Registers/Stores/StoresView.vue'),
          ignore_children: true,
          children: [
            {
              path: '',
              name: 'pgrs-registers-stores-list',
              component: () => import('@/Modules/PGRS/Registers/Stores/Views/ListView.vue'),
            },
            {
              path: 'create',
              name: 'pgrs-registers-stores-create',
              component: () => import('@/Modules/PGRS/Registers/Stores/Views/FormView.vue'),
            },
            {
              path: 'update/:id',
              name: 'pgrs-registers-stores-update',
              component: () => import('@/Modules/PGRS/Registers/Stores/Views/FormView.vue'),
            }
          ]
        },
      ],
    },
    //
    {
      path: 'activities',
      name: 'pgrs-activities',
      label: 'Atividades',
      permissions: ['pgrs.activities.*'],
      icon: 'ti ti-exchange',
      component: () => import('@/Modules/PGRS/Activities/ActivitiesView.vue'),
      children: [
        {
          path: 'occurrences',
          redirect: 'pgrs-activities-occurrences-list',
          label: 'Fiscalização',
          icon: 'ti ti-eye',
          permissions: ['pgrs.activities.occurrences'],
          component: () => import('@/Modules/PGRS/Activities/Occurrences/OccurrencesView.vue'),
          ignore_children: true,
          children: [
            {
              path: '',
              name: 'pgrs-activities-occurrences-list',
              component: () => import('@/Modules/PGRS/Activities/Occurrences/Views/ListView.vue'),
            },
            {
              path: 'create',
              name: 'pgrs-activities-occurrences-create',
              component: () => import('@/Modules/PGRS/Activities/Occurrences/Views/FormView.vue'),
            },
            {
              path: 'update/:id',
              name: 'pgrs-activities-occurrences-update',
              component: () => import('@/Modules/PGRS/Activities/Occurrences/Views/FormView.vue'),
            }
          ]
        },
        {
          path: 'map-companies',
          name: 'map-companies',
          label: 'Mapa de empresas',
          icon: 'ti ti-map',
          permissions: ['pgrs.activities.map'],
          component: () => import('@/Modules/PGRS/Activities/MapStores/MapStoreView.vue'),
          ignore_children: true,
        }
      ]
    }
  ]
}];
import Collection from "../Collections/Collection";
import FileEntity from "./Entities/FileEntity";
import FileHTTP from "./HTTP/FileHTTP";

export { FileCollection as default };

/**
 * File Collection
 * @author Roni Sommerfeld <roni@4tech.mobi>
 */
class FileCollection extends Collection {

  /**
   * Http Service
   */
  http_service = new FileHTTP;

  /**
   * Mape os tipos de arquivos
   *
   * @type {Object}
   */
  static file_types = {
    'image': 'image',
    'application/pdf': 'pdf',
    'application/msword': 'docs',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docs'
  };

  /**
   * Mapea os tipos de cores
   * pelo tipo do arquivo
   *
   * @type {Object}
   */
  static file_colors = {
    'image': 'success',
    'pdf': 'danger',
    'docs': 'info',
    'unknow': 'primary'
  }

  constructor() {
    super("disk_name");
  }

  /**
   * Deletar um arquivo da collecao
   * e da API caso exista o ID no mesmo
   * @public
   *
   * @param {FileEntity} file
   *
   * @returns {void}
   */
  deleteFile(file) {
    super.delete(file.disk_name);

    if (file.id) {
      this.http_service.deleteFile(file);
    }
  }

  /**
   * Adiciona os itens na coleçao vindo
   * da API do registro
   *
   * @public
   *
   * @param {Array} files
   *
   * @return {void}
   */
  addAttachFromAPI(files) {
    if(!files) {
      return;
    }

    if(files instanceof Array) {
      files.forEach(file => {
        this.add(this.mapFileFromAPI(file))
      });

      return;
    }

    this.add(this.mapFileFromAPI(files));
  }

  /**
   * Adiciona arquivo no relacionamento
   *
   * @public
   * @param {File|FileList} file_input
   * @returns {void}
   */
  addAttachFromInput(file_input) {
    if (file_input instanceof FileList) {
      Array.from(file_input).forEach(file => {
        this.add(this.mapFileFromInput(file));
      });
      return;
    }

    if (file_input instanceof File) {
      this.unique(this.mapFileFromInput(file_input));
    }

    return;
  }

  /**
   * Mapea os campos que vem da API
   *
   * @private
   * @param {Object} file
   * @returns {FileEntity}
   */
  mapFileFromAPI(file) {
    const file_entity = new FileEntity;
    file_entity.set(file);
    this.setColorTypes(file_entity, file);
    return file_entity;
  }

  /**
   * Mape o campo vindo do input para a
   * FileEntity
   *
   * @private
   * @param {File} file
   * @returns {FileEntity}
   */
  mapFileFromInput(file) {
    const file_entity = new FileEntity;
    file_entity.disk_name = Math.floor(Math.random() * 1E16);
    file_entity.file_name = file.name;
    file_entity.file_size = file.size;
    file_entity.content_type = file.type;
    file_entity.file_input = file;

    this.setColorTypes(file_entity, file);

    return file_entity;
  }

  /**
   * Adiciona a propriedade do Entity
   * file_type_name e file_type_color
   * sendo que o type é o nome do tipo do arquivo
   * e a color é apenas identificacao visual no front
   *
   * @private
   * @param {FileEntity} file_entity
   * @param {Object|File} file
   */
  setColorTypes(file_entity, file) {
    const file_type = file.type || file.content_type;

    file_entity.file_type_name = Object.entries(FileCollection.file_types).find(([type]) => file_type.startsWith(type))?.[1] || 'unknow';
    file_entity.file_type_color = FileCollection.file_colors[file_entity.file_type_name] || 'info';
  }
}
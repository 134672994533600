import HTTPService from "@/Services/HTTP/HTTPService";

export { FileHTTP as default };

class FileHTTP {
  /**
   * endpoint api
   * @private
   * @type {String}   
   */
  endpoint = '/api/v1/system/files';

  /**
   * Fetch list records
   *
   * @public
   * @returns {Object.<HTTPResponse>}
   */
  async deleteFile(params = {}) {
    let response = await HTTPService.post(
      `${this.endpoint}/delete`,
      params,
      'delete_file_system'
    );

    return response;
  }
}
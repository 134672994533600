import { createI18n } from 'vue-i18n';

export { LanguageSingleton as default };

/**
 * @description This class responsible for managing
 *the i18n rules
 *
 * @class LanguageService
 *
 * @author Roni Sommerfeld <roni@4tech.mobi>
 */
class LanguageService {

  /**
   * Vue-i18n
   * @type {Object.<VueI18n>}
   */
  i18n;

  /**
   * Object with messages locales
   * @type {Object}
   */
  messages = {};

  /**
   * Language Selected
   *
   * @type {String}
   * @default navigator.language
   */
  language = this.language_default;

  /**
   * Language Default
   *
   * @type {String}
   * @default pt-br
   */
  language_default = 'pt-br';

  /**
   * Available Languages
   *
   * @type {Array}
   */
  available_languages = ['pt-pt', 'pt-br', 'en'];

  /**
   * This function loads module locales and stores them in an object called "messages".
  * @returns {void}
   */
  async loadModulesLocales() {
    const locales = require.context('../../Modules', true, /Locales\/.*\.json$/)
    locales.keys().forEach(key => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)

      if (matched && matched.length > 1) {
        const locale = matched[1]
        const module_locales = locales(key);
        const property_locales = Object.keys(module_locales);

        if (property_locales) {
          property_locales.forEach(property => {
            if (this.messages[locale][property]) {
              this.messages[locale][property] = { ...this.messages[locale][property], ...module_locales[property] };
            } else {
              this.messages[locale][property] = module_locales[property];
            }
          });
        }
      }
    })
  }

  /**
  * This function loads global locales and stores them in an object called "messages".
 * @returns {void}
  */
  async loadGlobalMessages() {
    const locales = require.context('./locales', false, /[A-Za-z0-9-_,\s]+\.json$/i)
    locales.keys().forEach(key => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)
      if (matched && matched.length > 1) {
        const locale = matched[1]
        this.messages[locale] = { ...this.messages[locale], ...locales(key) }
      }
    })
  }

  /**
   * This function creates an instance of the i18n library with the locale set to 'pt-br' and loads
   * global messages and module locales.
   * @returns {Object} An instance of the i18n library with the locale set to 'pt-br', fallback locale set to
   * 'pt-br', and messages loaded from the global and module locales.
   */
  async createI18nInstance() {
    await this.loadGlobalMessages();
    await this.loadModulesLocales();

    return createI18n({
      locale: (this.language || 'pt-br'),
      fallbackLocale: (this.language || 'pt-br'),
      messages: this.messages,
      silentTranslationWarn: true,
      silentFallbackWarn: true,
    });
  }

  /**
   * Sets language system
   *
   * @param {String} value
   */
  setLanguage(value) {
    if (!this.i18n) return;

    this.language = value;
    this.i18n.global.locale = value;
  }

  /**
   * This function returns an instance of the i18n object asynchronously.
   * @returns The `getI18n()` function returns a Promise that resolves to the `i18n` object.
   */
  async getI18n() {
    if (!this.i18n) {
      this.i18n = await this.createI18nInstance();
    }
    return this.i18n;
  }
}

const LanguageSingleton = new LanguageService();

import Entity from "@/Services/Extends/Entity";

export {CityEntity as default};

/**
 * @class Entity referente a Cities
 * @author Roni Sommerfeld <roni@4tech.mobi>
 */
class CityEntity extends Entity{
    /**
     * @type {Number}
     */
    id;

    /**
     * @type {String}
     */
    name;

    /**
     * @type {Number}
     */
    state_id;
}
<template>
  <LayoutView>

    <template #content>
      <router-view></router-view>
    </template>

  </LayoutView>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import LayoutView from '@/Components/Layouts/LayoutView';
import UserSingleton from '@/Classes/User/UserSingleton';
import LayoutSingleton from './Services/App/LayoutSingleton';

const router = useRouter();

onMounted(() => {
  UserSingleton.event_message.on('user-not-logged', onUserNotLogged);
  onResize();
  window.addEventListener('resize', onResize)
});

onUnmounted(() => {
  UserSingleton.event_message.destroy('user-not-logged', onUserNotLogged);
  window.removeEventListener('resize', onResize)
});

const onUserNotLogged = () => {
  router.push({ name: 'auth-login' })
}

const onResize = () => {
  LayoutSingleton.device_viewport = window.innerWidth < 760 ? 'mobile' : 'desktop';
}
</script>

<style lang="scss">
@import './assets/css/style.css';
@import './assets/css/custom-styles.css';
</style>
